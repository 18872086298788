import React from 'react';
import './AboutUsComponent.css';
import ImageAU from '../../assets/images/aboutUsImage.jpg';

export const AboutUsComponent = () => {
    const changeColor = () => {
        document.getElementById("purpleRectangle").style.backgroundColor = "#ffbc3c";
    };

    const resetColor = () => {
        document.getElementById("purpleRectangle").style.backgroundColor = "#6044f4";
    };

    return (
        <div id='sobreNosotrosC'>
            
            <div className='AboutUsBlockMainContainer'>
                <div className='AboutUsLeftContainer'>
                    <div className="purple-rectangle" id="purpleRectangle"></div> {/* Rectángulo morado */}
                    <img src={ImageAU} id='imageAU' alt="About Us" onMouseOver={changeColor} onMouseOut={resetColor} /> {/* Imagen de perfil */}
                </div>
                <div className='AboutUsRightContainer'>
                    <h1>¿Quiénes Somos?</h1>
                    <p>En Next Step, nos dedicamos a impulsar la presencia online de empresas y profesionales, sin importar su tamaño o sector, a través de la creación de sitios web atractivos y funcionales.</p>
                    <p>Nuestro objetivo es potenciar su alcance y hacer crecer sus negocios en el mundo digital.
                    </p>
                </div>
            </div>
        </div>
    );
};
