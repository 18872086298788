import React, { useEffect, useRef } from 'react';
import './WelcomeComponent.css';
import lottie from 'lottie-web';
import animationData from '../../assets/animations/mainAnimation.json';

export const WelcomeComponent = () => {
    const animContainer = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: animContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData
        });
        lottie.setSpeed(0.6);
    }, []);

    // Función para manejar el clic en el botón "Comenzar"
    const handleButtonClick = (e) => {
        e.preventDefault();
        const targetSection = document.getElementById('bannerC');
        if (targetSection) {
            targetSection.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    return (
        <div id='WelcomeComponentC'>
            <div className='fistBlockMainContainer'>
                <div className='firstBlockTextContainer'>
                    <h1>¡Da el Next Step y Haz Tus Sueños Realidad!</h1>
                    <p>Transformamos la presencia digital de las empresas con sitios web personalizados y atractivos. Potenciamos tu visibilidad online para impulsar el crecimiento de tu negocio.</p>
                    <button className='buttonFistBlockMainContainer' onClick={handleButtonClick}>Comenzar</button>
                </div>
                <div className='container' ref={animContainer} style={{ width: '100%', height: 'auto', maxWidth: '500px', margin: '0 auto' }}></div>
            </div>
        </div>
    );
};
