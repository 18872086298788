import './App.css';
import { ApplicationComponent } from './components/ApplicationComponent/ApplicationComponent';

function App() {

  return (
    <div className="App">
      <ApplicationComponent></ApplicationComponent>
    </div>
  );
}

export default App;