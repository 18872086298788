import React, { useState } from 'react';
import './FAQComponent.css';

export const FAQComponent = () => {
  const faqs = [
    {
      "question": "¿Cómo puedo contactar con ustedes?",
      "answer": "Puedes completar el formulario a continuación con tus datos y nos pondremos en contacto contigo"
    },
    {
      "question": "¿Por qué es importante tener presencia en línea?",
      "answer": "La presencia en línea aumenta la visibilidad y facilita la comunicación y venta de productos o servicios."
    },
    {
      "question": "¿Necesito saber mucho de software para trabajar con ustedes?",
      "answer": "No hace falta ser un experto, nosotros te acompañarémos en todas las fases del desarrollo para poder construirte el sitio que necesitas"
    },
    {
      "question": "¿Qué beneficios ofrece un diseño que refleje la esencia del negocio?",
      "answer": "Un diseño que captura la esencia del negocio transmite la identidad de la marca y fortalece la conexión con la audiencia."
    },
    {
      "question": "¿Qué ventajas tiene mi empresa/negocio al tener presencia en línea?",
      "answer": "Una presencia en línea sólida permite a los negocios a competir en visibilidad y alcance, mejorar la credibilidad y aumentar las ventas."
    },
    {
      "question": "¿Tengo que tener una empresa grande para trabajar con ustedes?",
      "answer": "No, trabajamos con todo tipo de emprendedores, profesionistas y empresas para ayudarlos a dar el Next Step en su negocio"
    },
    
    {
      "question": "¿Cómo puede ayudarme Next Step a mi negocio?",
      "answer": "En Next Step desarrollamos sitios web que ayudan a aumentar la visibildad, generar leads, mejorar la reputación de la marca y así crecer las ventas."
    }
  ]
  ;

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id='preguntasFrecuentesC'>
    <div className='FAQBody'>
      <div className='FAQMainBlock'>
        <h1>Preguntas Frecuentes</h1>
        <div className='faq-list'>
          {faqs.map((faq, index) => (
            <div key={index} className='faq-item'>
              <div
                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};
