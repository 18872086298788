import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './FormComponent.css'

export const FormComponent = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_x50l1jy', 'template_1eh0k2g', form.current, {
        publicKey: '6KszO86UTWNnoD3UD',
      })
      .then(
        () => {
          alert('Correo Enviado!');
        },
        (error) => {
          alert('ERROR en el envío del correo :(', error.text);
        },
      );
  };

  return (
      <div id='formContacto'>
        <div className='formMainContainer'>
          <h1>Contacto</h1>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="user_name">Nombre</label>
              <input type="text" id="user_name" name="user_name" />
            </div>
            <div className="form-group">
              <label htmlFor="user_lastname">Apellido</label>
              <input type="text" id="user_lastname" name="user_lastname" />
            </div>
            <div className="form-group">
              <label htmlFor="user_email">Correo</label>
              <input type="email" id="user_email" name="user_email" />
            </div>
            <div className="form-group">
              <label htmlFor="message">Mensaje</label>
              <textarea id="message" name="message"></textarea>
            </div>
            <button type="submit">Enviar</button>
          </form>
        </div>
      </div>
  );
};