import './HeaderComponent.css';
import Imagen from '../../assets/images/whiteLogo.png';

export const HeaderComponent = ({ turnOnContactPage, turnOnHomePage, turnOnPortfolioPage}) => {
    const handleClick = (e, id) => {
        e.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    return (
        <div className="mainContainer">
            <div className="leftContainer">
                <img onClick={(e) => handleClick(e, 'WelcomeComponentC')} src={Imagen} alt="Logo" />                
                <h1 onClick={(e) => handleClick(e, 'WelcomeComponentC')}>Next Step MKT</h1>
            </div>
            <div className="rightContainer">
                <div className="rightContainerButtonsHolder">
                    {/*<button className='buttonHeader' onClick={turnOnContactPage}>Contact Page!</button>
                    <button className='buttonHeader' onClick={turnOnPortfolioPage}>Portfolio Page!</button>*/}
 
<button className="buttonHeader" onClick={(e) => handleClick(e, 'sobreNosotrosC')}>Nosotros</button>
                    <button className="buttonHeader" onClick={(e) => handleClick(e, 'serviciosC')}>Servicios</button>
                    <button className="buttonHeader" onClick={(e) => handleClick(e, 'propuestaC')}>Propuesta</button>
                    <button className="buttonHeader" onClick={(e) => handleClick(e, 'productsContainer')}>Proyectos</button>
                    <button className="buttonHeader" onClick={(e) => handleClick(e, 'preguntasFrecuentesC')}>FAQ</button>
                    <button className="buttonHeader" onClick={(e) => handleClick(e, 'chooseUsBody')}>Elígenos</button>
                    <button className="buttonHeader" onClick={(e) => handleClick(e, 'formContacto')}>Contacto</button> 
                </div>
            </div>
        </div>
    );
};