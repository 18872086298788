import React, {useEffect, useRef} from 'react';
import './ProposalComponent.css';
import lottie from 'lottie-web';
import animationData from '../../assets/animations/laptopAnimation.json';

export const ProposalComponent = () => {

    const animContainer = useRef(null);
    
    useEffect(() => {
        lottie.loadAnimation({
            container: animContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData
        })
        lottie.setSpeed(0.6);
        
    }, [])

    return (
        <div id='propuestaC'>
            <div className='proposalBlockMainContainer'>
                <div className='proposalBlockTextContainer'>
                    <h1>Diseñamos y Construimos tu Sitio Web Ideal</h1>
                    <p>Nuestros sitios web son seguros, funcionales y adaptables al crecimiento de tu negocio. Con un enfoque centrado en el usuario, cada elemento se diseña estratégicamente para una experiencia de navegación intuitiva y atractiva</p>
                    
                </div>
                <div className='container' ref={animContainer} style={{ width: '100%', height: 'auto', maxWidth: '400px', margin: '0 auto' }}></div> 
                
            </div>
        </div>
    )
}
