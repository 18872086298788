import React from 'react'
import './BannerComponent.css'

export const BannerComponent = () => {
    return (
        <div id='bannerC'>
            <div className='scheduleBannerBlockMainContainer'>
                <h1>¿Listo Para Trabajar Juntos?</h1>
                <p>Agenda una reunión y cotiza con nosotros</p>
                <p className='scheduleBannerText'>Es Gratis!</p>
            </div>
        </div>
    )
}