import React from 'react'
import { BannerComponent } from '../BannerComponent/BannerComponent'
import { ChooseUsComponent } from '../ChooseUsComponent/ChooseUsComponent';
import { FormComponent } from '../FormComponent/FormComponent';
import { ProductsComponent } from '../ProductsComponent/ProductsComponent';
import { ProposalComponent } from '../ProposalComponent/ProposalComponent';
import { ServicesComponent } from '../ServicesComponent/ServicesComponent';
import { WelcomeComponent } from '../WelcomeComponent/WelcomeComponent';
import {AboutUsComponent} from '../AboutUsComponent/AboutUsComponent'
import { FAQComponent } from '../FAQComponent/FAQComponent';

export const HomePageComponent = () => {
  return ( 
    <div>
        
              <WelcomeComponent></WelcomeComponent>
              <AboutUsComponent></AboutUsComponent>
              <ServicesComponent></ServicesComponent>
              <ProposalComponent></ProposalComponent> 
              <ProductsComponent></ProductsComponent>
              <FAQComponent></FAQComponent> 
              <ChooseUsComponent></ChooseUsComponent>
              <BannerComponent></BannerComponent>
              <FormComponent></FormComponent>
              
    </div>
  )
}
