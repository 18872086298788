import React from "react";
import "./ProductsComponent.css";
// import marketImage from "../../assets/images/grocery-store-1.png";
import portfolioImage from "../../assets/images/briefcase.png";
import cvImage from "../../assets/images/cv.png";
import webPageImage from "../../assets/images/landing-page.png";
import catalogImage from "../../assets/images/catalog.png";

export const ProductsComponent = () => {
    return (
        <div id="productsContainer">
            <h1>Productos Destacados</h1>
        
            <div className="productBlockMainContainer">
                
                {/* <div className="productBlockMainItem">
                    <h2>Tienda en linea</h2>
                    <p>
                    Lleva tu negocio al siguiente nivel con una experiencia de compra única.
                    </p>
                    <img src={marketImage} className="smallImage" alt="Imagen 1" />
                </div>
                */}
                
                <div className="productBlockMainItem">
                    <h2>Portafolio</h2>
                    <p>
                    Destaca tus habilidades y logros profesionales.
                    </p>
                    <img src={portfolioImage} className="smallImage" alt="Imagen 2" />
                </div>
                <div className="productBlockMainItem">
                    <h2>Curriculum</h2>
                    <p>
                    Impresiona a empleadores con un currículum digital moderno.
                    </p>
                    <img src={cvImage} className="smallImage" alt="Imagen 3" />
                </div>
                <div className="productBlockMainItem">
                    <h2>Sitio Web</h2>
                    <p>
                    Diseñamos sitios personalizados que cautivan a tu audiencia.
                    </p>
                    <img src={webPageImage} className="smallImage" alt="Imagen 4" />
                </div>
                <div className="productBlockMainItem">
                    <h2>Menú/Catálogo</h2>
                    <p>
                    Muestra tus productos o servicios de forma atractiva.
                    </p>
                    <img src={catalogImage} className="smallImage" alt="Imagen 2" />
                </div>
            </div>
        </div>
    );
};
