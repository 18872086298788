import React, { useState } from 'react';
import { FooterComponent } from '../FooterComponent/FooterComponent';
import { HeaderComponent } from '../HeaderComponent/HeaderComponent';
import { HomePageComponent } from '../HomePageComponent/HomePageComponent';
import { ContactPageComponent } from '../ContactPageComponent/ContactPageComponent';
import { PortfolioPageComponent } from '../PortolioPageComponent/PortfolioPageComponent';
import './ApplicationComponent.css'

export const ApplicationComponent = () => {
    const [isHomePageVisible, setHomePageVisible] = useState(true);
    const [isContactPageVisible, setContactPageVisible] = useState(false);
    const [isPortfolioPageVisible, setPortfolioPageVisible] = useState(false);

    const turnOffPages = () => {
        setHomePageVisible(false);
        setContactPageVisible(false);
        setPortfolioPageVisible(false);
    }

    const turnOnHomePage = () => {
        turnOffPages();
        setHomePageVisible(true);
    }

    const turnOnContactPage = () => {
        turnOffPages();
        setContactPageVisible(true);
    }

    const turnOnPortfolioPage = () => {
        turnOffPages();
        setPortfolioPageVisible(true);
    }

    return (
        <div>
            <HeaderComponent turnOnContactPage={turnOnContactPage} turnOnHomePage={turnOnHomePage} turnOnPortfolioPage={turnOnPortfolioPage}/>       
                <div className={isHomePageVisible ? '' : 'hidden'}>
                    <HomePageComponent />
                </div>
                <div className={isContactPageVisible ?  '' : 'hidden'}>
                    <ContactPageComponent />
                </div>
                <div className={isPortfolioPageVisible ?  '' : 'hidden'}>
                    <PortfolioPageComponent/>
                </div>
            <FooterComponent />
        </div>
    )
}
