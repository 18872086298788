import React from 'react'
import './ChooseUsComponent.css'
import apasionadosImage from '../../assets/images/collaboration.png';
import profesionalesImage from '../../assets/images/user.png';
import soporteImage from '../../assets/images/motivation.png';

export const ChooseUsComponent = () => {
    return (
        <div id='chooseUsBody'>
            <h1>¿Por qué Elegirnos?</h1>
            <div className='chooseUsBlockMainContainer'>
                <div className='chooseUsBlockTextContainer'>
                    <img className='chooseUsImages' src={soporteImage} />
                    <h2>Apasionados</h2>
                    <p>Impulsamos el éxito de tu empresa con páginas web creadas con pasión y soluciones innovadoras.</p>
                </div>
                <div className='chooseUsBlockTextContainer'>
                    <img className='chooseUsImages' src={profesionalesImage} />
                    <h2>Profesionales</h2>
                    <p>Garantizamos excelencia en cada proyecto con un equipo altamente profesional que destaca por su calidad y atención al detalle.</p>
                </div>
                <div className='chooseUsBlockTextContainer'>
                    <img className='chooseUsImages'src={apasionadosImage} />
                    <h2>Soporte</h2>
                    <p>Brindamos un soporte excepcional, siempre estamos disponibles para resolver dudas y garantizar tu éxito continuo.</p>
                </div>
            </div>
        </div>
    )
}
