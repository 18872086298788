import React, { useEffect, useRef } from 'react';
import { FormComponent } from '../FormComponent/FormComponent'
import './ContactPageComponent.css'
import lottie from 'lottie-web';
import animationData from '../../assets/animations/ContactPageAnimation.json';
import { FAQComponent } from '../FAQComponent/FAQComponent';

export const ContactPageComponent = () => {
  
  const animContainer = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: animContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationData
        });
        lottie.setSpeed(0.5
          
        );
    }, []);

  return (
    <div>
        <div className='contactPageMainContainer'>
          <div className='contactPageLeftContainer'>
            <div className='container' ref={animContainer} style={{ width: '100%', height: 'auto', maxWidth: '500px', margin: '0 auto' }}></div>
          </div>
          <div className='contactPageRightContainer'>
          <FormComponent></FormComponent>
          </div> 
        </div><FAQComponent></FAQComponent>    
    </div>
  )
}
